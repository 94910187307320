import {graphql, useStaticQuery} from "gatsby"
import React from "react"
import {useState} from "react";
import {Row, Col, Nav, Form, Button, Alert} from "react-bootstrap";
import styles from "./footer.module.css";
// import addToMailchimp from 'gatsby-plugin-mailchimp'
import addToMailchimp from "../services/mailchimp";
import Img from "gatsby-image";

const Footer = ()  => {
  const [formState, setFormValues] = useState({email: null});
  const [showAlert, setShowAlert] = useState({show: false, msg: ""});
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [formResult, setFormResult] = useState({});
  const submitParentingNewsletterUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=1e75706ef5";
  const data = useStaticQuery(graphql`
    query FooterNavbarQuery {
      site {
        siteMetadata {
          menuLinks {
            title
            link
            headerOnly
          }
        }
      }
      podcastIcon: file(relativePath: { eq: "footer/podcast-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebookIcon: file(relativePath: { eq: "footer/facebook-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagramIcon: file(relativePath: { eq: "footer/instagram-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedinIcon: file(relativePath: { eq: "footer/linkedin-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youtubeIcon: file(relativePath: { eq: "footer/youtube-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const _handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requiredFields = ["email"];
      for (const field in formState) {
        if(requiredFields.indexOf(field) !== -1) {
          if(!formState[field]) {
            setShowErrorAlert({
              show: true,
              onField: field.charAt(0).toUpperCase()+ field.slice(1)
            });

            return;
          }
        }
      }

      const result = await addToMailchimp(formState.email, {} , submitParentingNewsletterUrl);
      const alreadyAMemberError = formState.email + ` is already subscribed to list`
      if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
        setShowAlert({
          show: true,
          msg: 'You already subscribed to list!'
        });
      } else {
        setFormResult(result);
        setShowAlert({
          show: true,
          msg: result.msg
        });
      }
    } catch (e) {
      console.log(e)
    }
  };

  const handleFormChange = (e) => setFormValues({
    ...formState,
    [e.target.name]: e.target.value,
  });

  return (
    <footer>
      <div className={[styles.footerContainer, "container-fluid"].join(" ")}>
        <Row className={"mt-sm-5 justify-content-center " + styles.navbar}>
          <Col xs={12} sm={12} md={{ span: 3, offset: 0 }}>
            <h4 className={styles.footerColumnTitle}><span>Quick</span> Links</h4>
            {data.site.siteMetadata.menuLinks.map(item => (
              <div key={item.title}>
                {
                  !item.headerOnly &&
                  <Nav.Link className={styles.red} href={item.link}>{item.title}</Nav.Link>
                }
              </div>
            ))}
          </Col>

          <Col xs={12} sm={12} md={3} className={styles.socialMedia}>
            <h4 className={styles.footerColumnTitle}><span>Stay</span> in Touch</h4>
            <a variant="link"
                    href="https://www.instagram.com/momsonmaternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.instagramIcon.childImageSharp.fluid}/>
            </a>

            <a variant="link"
                    href="https://www.facebook.com/MomsOnMaternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.facebookIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.youtubeIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.podcastIcon.childImageSharp.fluid}/></a>

            <a variant="link"
                    href="https://www.linkedin.com/company/moms-on-maternity/"
                    target="_blank"
            ><Img className={styles.socialMediaIcon} fluid={data.linkedinIcon.childImageSharp.fluid}/></a>
          </Col>

          <Col xs={12} sm={12} md={{ span: 3, offset: 0 }}>
            <h4 className={[styles.subscribeToNewsletterTitle, styles.footerColumnTitle].join(' ')}><span>Subscribe</span> to our parenting newsletter today!</h4>

            <Form onSubmit={_handleSubmit}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Col className={styles.emailInput}>
                  <Form.Control type="email" name="email" placeholder="Enter email address" onChange={handleFormChange} />
                </Col>
              </Form.Group>

              <Button type="submit" className={[styles.submitButton, styles.redirectButton]}>
                Subscribe now
              </Button>
            </Form>

            <Row>
              <Col xs={12} sm={8} className={styles.alertMessage}>
                {
                  showAlert.show && formResult.result === 'error' &&
                  <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>{showAlert.msg}</Alert>
                }
                {
                  showAlert && formResult.result === 'success' &&
                  <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>{formResult.msg}</Alert>
                }
                {
                  showError.show &&
                  <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                  >{showError.onField} field should not be empty</Alert>
                }
              </Col>
            </Row>
          </Col>
        </Row>


        <Row className={"mt-sm-3 justify-content-center " + styles.navbar}>
          <Col xs={10} sm={8} className={styles.copyRightMessage}>
            <p>&copy; 2017-2024 Moms on Maternity is a Trademarked Social Benefit Corporation. All rights reserved. </p>
          </Col>
        </Row>
      </div>
    </footer>
  )
};

export default Footer
